/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Toolbar_data$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Home_data$ref: FragmentReference;
declare export opaque type Home_data$fragmentType: Home_data$ref;
export type Home_data = {|
  +$fragmentRefs: Toolbar_data$ref,
  +$refType: Home_data$ref,
|};
export type Home_data$data = Home_data;
export type Home_data$key = {
  +$data?: Home_data$data,
  +$fragmentRefs: Home_data$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "Home_data",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "Toolbar_data",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd2e244ccad5067472e1c26d70b9c0990';
module.exports = node;

import React from 'react';
import ReactDOM from 'react-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, commitMutation, createFragmentContainer } from 'react-relay';

import { useRelay } from '../hooks';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingBottom: '10rem',
  },
  title: {
    fontSize: '2.5rem',
    fontWeight: 500,
    paddingBottom: '1.25rem',
    marginTop: '12%',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.75rem',
    },
  },
  subTitle: {
    fontSize: '1rem',
    lineHeight: '1.75rem',
    paddingBottom: '2rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '2rem',
    },
  },
  form: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'top',
    marginBottom: '2rem',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  field: {
    marginBottom: theme.spacing(1),
  },
  button: {
    height: 56,
    textTransform: 'none',
    fontSize: '1rem',
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '1rem',
    },
  },
  social: {
    '&a': {
      padding: 20,
    },
  },
}));

function Home(props) {
  const relay = useRelay();
  const inputRef = React.useRef(null);
  const [state, setState] = React.useState({ email: '', labelWidth: 0 });
  const s = useStyles();

  function handleChange({ target }) {
    setState({
      ...state,
      [target.id]: target.value,
      ...(!target.value.length && { errors: null }),
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    const search = event.target.search.value;
    window._paq.push(['trackSiteSearch', search, 'Experts', false]);
    setState({ ...state, errors: null, success: false, loading: true });
    commitMutation(relay.environment, {
      mutation: graphql`
        mutation HomeMutation($input: SubscribeInput!) {
          subscribe(input: $input) {
            clientMutationId
          }
        }
      `,
      variables: { input: { email: state.email } },
      onCompleted(res, errors) {
        const err = errors && errors[0];
        if (err) {
          setState({
            ...state,
            loading: false,
            success: false,
            errors: (err.state && err.state.email) || [err.message],
          });
          ReactDOM.findDOMNode(inputRef.current).focus();
        } else {
          setState({
            ...state,
            email: '',
            success: true,
            loading: false,
            errors: null,
          });
          ReactDOM.findDOMNode(inputRef.current).blur();
        }
      },
    });
  }

  return (
    <Container className={s.root} maxWidth="sm">
      <Typography className={s.title} variant="h1">
        Expertise at your fingertips
      </Typography>
      <Typography className={s.subTitle} variant="h2">
        Quickly connect with an expert who can answer your questions
      </Typography>
      <form className={s.form} onSubmit={handleSubmit}>
        <Autocomplete
          id="search-experts"
          autoComplete
          includeInputInList
          freeSolo
          disableOpenOnFocus
          renderInput={params => (
            <TextField
              {...params}
              inputRef={inputRef}
              name="search"
              label="Search by area of expertise or name"
              placeholder="E.g. Biofuels or John Smith"
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          )}
          style={{ width: '100%' }}
        />
      </form>
      <div className={s.social}>
        <a
          href="https://fb.me/advisor.im"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg style={{ width: 32, height: 32 }} viewBox="0 0 24 24">
            <path
              fill="#000000"
              d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z"
            />
          </svg>
        </a>
        <a
          href="https://twitter.com/advisorim"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg style={{ width: 32, height: 32 }} viewBox="0 0 24 24">
            <path
              fill="#000000"
              d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M17.71,9.33C18.19,8.93 18.75,8.45 19,7.92C18.59,8.13 18.1,8.26 17.56,8.33C18.06,7.97 18.47,7.5 18.68,6.86C18.16,7.14 17.63,7.38 16.97,7.5C15.42,5.63 11.71,7.15 12.37,9.95C9.76,9.79 8.17,8.61 6.85,7.16C6.1,8.38 6.75,10.23 7.64,10.74C7.18,10.71 6.83,10.57 6.5,10.41C6.54,11.95 7.39,12.69 8.58,13.09C8.22,13.16 7.82,13.18 7.44,13.12C7.81,14.19 8.58,14.86 9.9,15C9,15.76 7.34,16.29 6,16.08C7.15,16.81 8.46,17.39 10.28,17.31C14.69,17.11 17.64,13.95 17.71,9.33Z"
            />
          </svg>
        </a>
        <a
          href="https://instagram.com/advisor.im/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg style={{ width: 32, height: 32 }} viewBox="0 0 24 24">
            <path
              fill="#000000"
              d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
            />
          </svg>
        </a>
        <a
          href="mailto:hello@advisor.im"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg style={{ width: 32, height: 32 }} viewBox="0 0 24 24">
            <path
              fill="#000000"
              d="M4,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4M12,11L20,6H4L12,11M4,18H20V8.37L12,13.36L4,8.37V18Z"
            />
          </svg>
        </a>
      </div>
    </Container>
  );
}

export default createFragmentContainer(Home, {
  data: graphql`
    fragment Home_data on Query {
      ...Toolbar_data
    }
  `,
});
